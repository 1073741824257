<script>
import { mask } from "vue-the-mask";
import headers from "../config/headers.json";
import ImageViewer from "@/components/viewers/Image";

export default {
  name: "Patients",

  components: {
    ImageViewer,
  },

  directives: { mask },

  data: () => ({
    headers,
    patients: [],
    dialog: false,
    items: null,
    selectedPatient: null,
    loading: false,
    search: "",
  }),

  computed: {
    payment() {
      return {
        certificate: this.$t("profile.selects.payments.certificate"),
        insurance: this.$t("profile.selects.payments.insurance"),
        account: this.$t("profile.selects.payments.account"),
      };
    },
    allowedPatients() {
      if (this.search) {
        console.log(this.search);
        return this.patients.filter((item) =>
          item.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }

      return this.patients;
    },
  },

  async beforeMount() {
    await this.getData();
  },

  methods: {
    async getData() {
      this.loading = true;
      try {
        this.patients = await this.$API.profile().getPatients();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    async viewPatientInfo(item) {
      try {
        this.items = await this.$API.profile().getPatientInfo(item.id);
        this.selectedPatient = item;
        this.dialog = true;
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    closeDialog() {
      this.dialog = false;
      this.selectedPatient = null;
      this.items = null;
    },
    openPdf(item) {
      window.open(
        `https://inn-api.powerit.dev/api/v1/appointment_services/${item.id}/investigation_report_demo`,
        "_blank"
      );
    },
  },
};
</script>

<template>
  <v-card max-height="545" height="100%" width="800" elevation="1">
    <v-card-title v-if="patients.length" class="pa-0 px-6 pt-4 mb-2">
      <v-text-field
        prepend-inner-icon="mdi-magnify"
        dense
        v-model="search"
        hide-details
        :label="$t('profile.form.fields.find_patient')"
      ></v-text-field>
    </v-card-title>
    <v-card-text
      v-if="allowedPatients.length"
      style="max-height: 475px; overflow-y: scroll"
      class="py-5"
    >
      <v-row>
        <transition-group name="list" tag="div" class="row d-flex mx-1">
          <v-col cols="6" v-for="(item, index) in allowedPatients" :key="index">
            <v-card outlined>
              <v-card-title class="py-1">
                {{ item.name }}
              </v-card-title>
              <v-card-text class="pt-2 pb-1">
                <div class="py-1">
                  <v-icon class="mr-2">mdi-email</v-icon>
                  <span>{{ item.email }}</span>
                </div>
                <div class="py-1">
                  <v-icon class="mr-2">mdi-phone</v-icon>
                  <span>{{ item.phones }}</span>
                </div>
                <div class="py-1 d-flex">
                  <v-icon class="mr-2">mdi-calendar</v-icon>
                  <span>{{ item.birthdate }}</span>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="viewPatientInfo(item)"
                    outlined
                    x-small
                    color="innirm"
                  >
                    {{ $t("global_buttons.view_info") }}
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </transition-group>
      </v-row>
    </v-card-text>
    <v-card-text v-else-if="!loading && !allowedPatients.length">
      <v-alert border="top" colored-border type="info" elevation="1">
        Информация отсутствует
      </v-alert>
    </v-card-text>
    <v-dialog scrollable v-model="dialog">
      <v-card v-if="dialog" max-height="800px">
        <v-card-title class="d-flex">
          {{ selectedPatient.name }} investigations
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog()" text fab x-small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            fixed-header
            hide-default-footer
            :items="items"
            :headers="headers"
          >
            <template #item.actions="{ item }">
              <div class="d-flex justify-space-between">
                <image-viewer
                  v-if="item.doctor_referral_letter"
                  :src="item.doctor_referral_letter.url"
                  rounded
                >
                </image-viewer>
                <v-btn v-else disabled text fab x-small>
                  <v-icon>mdi-camera</v-icon>
                </v-btn>
                <v-btn
                  :disabled="!item.description"
                  @click="openPdf(item)"
                  text
                  fab
                  x-small
                >
                  <v-icon>mdi-file-pdf</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<style lang="scss" scoped>
#pie-chart::v-deep .apexcharts-tooltip-series-group {
  background: rgba(0, 0, 0, 0.75) !important;
}
svg {
  fill: var(--v-primary-base);
}
.bordered {
  position: relative;

  &:before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: var(--v-primary-base);
    content: " ";
    height: 30px;
    width: 2px;
    border-radius: 50px !important;
  }
}
.pie-list {
  li:not(:last-child) {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
}
.comments-container > div {
  &:hover {
    background: var(--v-greyish-base);
  }
  .status-container {
    .v-icon {
      &:after {
        opacity: 0;
      }
      &.edit-icon {
        &:hover {
          color: var(--v-primary-base);
        }
      }
      &.remove-icon {
        &:hover {
          color: var(--v-error-base);
        }
      }
    }
  }
}
.card-container::v-deep {
  .fb {
    background: #5158d8;
  }
  *:not(.v-btn span) {
    color: #fff;
  }
}
.list-enter-active,
.list-leave-active {
  transition: all 0.2s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
</style>
