<script>
import { mask } from "vue-the-mask";
import ImageViewer from "@/components/viewers/Image";
import login from "../../auth/views/login";

export default {
  name: "History",

  components: {
    ImageViewer,
  },

  directives: { mask },

  data: () => ({
    history: null,
    dialog: false,
    selectedItem: null,
    emails: [],
    searchInput: "",
    errorMessages: {},
    scrolled: false,
  }),

  computed: {
    payment() {
      return {
        certificate: this.$t("profile.selects.payments.certificate"),
        insurance: this.$t("profile.selects.payments.insurance"),
        account: this.$t("profile.selects.payments.account"),
      };
    },
  },

  async beforeMount() {
    await this.getData();
  },

  methods: {
    async search(value, key, field, label) {
      return await this.$API.profile().searchDoctorEmail(value, field, label);
    },
    async getData() {
      try {
        this.history = await this.$API.profile().getHistory();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async share() {
      try {
        await this.$API.profile().share({
          service_id: this.selectedItem.id,
          emails: this.emails.map((item) => item.trim()),
        });
        this.selectedItem = null;
        this.dialog = false;
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_sent")
        );
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }

        await this.$store.dispatch(
          "alert/showError",
          Object.values(e.errors)[0] || e.messages
        );
      }
    },
    openShareDialog(item) {
      this.selectedItem = item;
      this.dialog = true;
    },
    addItem(item) {
      if (item?.trim()) {
        this.emails.push(item.trim());
        this.searchInput = "";
      }
    },
    editItem() {
      if (this.searchInput) {
        return;
      }

      this.searchInput = this.emails.pop();
    },
    openPDF(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<template>
  <v-card v-if="this.history" elevation="1">
    <v-card-text>
      <v-timeline>
        <v-timeline-item v-for="(item, index) in history" :key="index" large>
          <template #icon>
            <image-viewer
              size="48"
              v-if="item.doctor_referral_letter"
              rounded
              :src="item.doctor_referral_letter.url"
            />
            <v-avatar v-else></v-avatar>
          </template>
          <template #opposite>
            <span>{{ item.reservation_time }}</span>
          </template>
          <v-card class="elevation-2">
            <v-card-title class="title">
              <div>{{ item.service }}</div>
              <div class="caption" style="color: rgba(0, 0, 0, 0.36)">
                {{ item.number }}
              </div>
            </v-card-title>
            <v-card-text class="pb-1">
              <div v-if="item.imagist">
                Imagist: <strong>{{ item.imagist }}</strong>
              </div>
              <div v-if="item.technician">
                Technician: <strong>{{ item.technician }}</strong>
              </div>
              <div class="text-right">
                {{ payment[item.payment_type] }}
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="d-flex">
              <v-btn
                @click="openShareDialog(item)"
                color="info"
                x-small
                outlined
              >
                <span>
                  {{ $t("global_buttons.share") }}
                </span>
                <v-icon>mdi-share</v-icon>
              </v-btn>
              <v-btn
                @click="openPDF(item.description)"
                v-if="item.description"
                color="error"
                x-small
                outlined
              >
                <span>PDF</span>
                <v-icon class="ml-1" size="18">mdi-file-pdf</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <strong> {{ item.price }} {{ $t("global_text.mdl") }} </strong>
            </v-card-actions>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
    <v-bottom-sheet persistent v-model="dialog">
      <v-card v-if="dialog">
        <v-card-title class="mb-3">
          {{ $t("profile.headers.share_results") }}
        </v-card-title>
        <v-card-text class="py-0">
          <v-form @submit.prevent.stop>
            <v-row>
              <v-col cols="12" sm="12" lg="12" class="emails pt-0">
                <v-combobox
                  @keypress.space="addItem(searchInput)"
                  @blur="addItem(searchInput)"
                  @keydown.delete="editItem()"
                  :search-input.sync="searchInput"
                  class="pt-0"
                  chips
                  small-chips
                  multiple
                  :label="$t('profile.form.fields.share')"
                  v-model="emails"
                  :errorMessages="errorMessages.emails"
                >
                  <template #selection="{ attrs, item, parent, selected }">
                    <v-chip
                      class="ma-1"
                      v-bind="attrs"
                      :input-value="selected"
                      label
                      small
                    >
                      <span class="pr-2">
                        {{ item }}
                      </span>
                      <v-icon small @click="parent.selectItem(item)">
                        mdi-close
                      </v-icon>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" text>Отмена</v-btn>
          <v-btn @click="share()" color="primary" class="mr-2" depressed
            >Подтвердить</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </v-card>
</template>

<style lang="scss" scoped>
#pie-chart::v-deep .apexcharts-tooltip-series-group {
  background: rgba(0, 0, 0, 0.75) !important;
}
svg {
  fill: var(--v-primary-base);
}
.bordered {
  position: relative;

  &:before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: var(--v-primary-base);
    content: " ";
    height: 30px;
    width: 2px;
    border-radius: 50px !important;
  }
}
.emails::v-deep .v-input__slot {
  height: 32px;
}
.pie-list {
  li:not(:last-child) {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
}
.comments-container > div {
  &:hover {
    background: var(--v-greyish-base);
  }
  .status-container {
    .v-icon {
      &:after {
        opacity: 0;
      }
      &.edit-icon {
        &:hover {
          color: var(--v-primary-base);
        }
      }
      &.remove-icon {
        &:hover {
          color: var(--v-error-base);
        }
      }
    }
  }
}
.card-container::v-deep {
  .fb {
    background: #5158d8;
  }
  *:not(.v-btn span) {
    color: #fff;
  }
}
</style>
